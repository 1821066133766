import { Component, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { CertificatesService } from './services/certificates.service';

import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  form!: FormGroup

  inMaintenance = signal(environment.inMaintenance)
  showLogin = true;

  private certificatesServices = inject(CertificatesService)
  private fb = inject(FormBuilder)

  ngOnInit(): void {
    this.form = this.fb.group({
      pass: new FormControl('')
    })
    !this.inMaintenance() && this.certificatesServices.getAllCertificates()
  }

  verified() {
    const { pass } = this.form.value
    if (pass === 'certificados') {
      this.showLogin = false
    }
  }

}
